import { Link } from "gatsby";
import BlogRoll from "../components/BlogRoll";
import React from "react";
import BackgroundSection from "../components/BackgroundSection";
import ImageCarouselWithText from "../components/ImageCarouselWithText";

export function IndexPageSharedComponents(props) {
  return <div>

    {(() => {
      if (!props.hide) {
        return (
          <BackgroundSection className={ "is-fullheight-with-navbar" }
                             imageData={ !!props.childImageSharp ? props.childImageSharp.fluid : props.image }/>
        )
      }
    })()}

    <section className="section section--gradient">
      <div className="container">
        <div className="section pt-0 ">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                {/*<div className="column is-12 has-text-centered pb-5 mb-2 pt-0">*/}
                {/*  <Link className="btn" to="/contact">*/}
                {/*    <img className={'contact-button'} src={`${withPrefix('/')}img/Button-yhteystiedot.png`}/>*/}
                {/*  </Link>*/}
                {/*</div>*/}
                {/*<Features gridItems={ props.gridItems }/>*/}
                <ImageCarouselWithText gridItems={ props.gridItems }/>
                {/*<div className="columns">*/}
                {/*  <div className="column is-12 has-text-centered text-wrap">*/}
                {/*    <Link className="btn" to="/contact">*/}
                {/*      <img className={'contact-button'} src={`${withPrefix('/')}img/tilausajot.png`}/>*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="column is-12 has-text-centered">
                  <Link className="btn" to="/products">
                    { props.productlink }
                  </Link>
                </div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2 text-wrap">
                    { props.blogheader }
                  </h3>
                  <BlogRoll/>
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      { props.bloglink }
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>;
}